<template>
  <div>
    <b-card no-body class="h-100">
      <b-card-header>
        <b-card-title>News Trend</b-card-title>
        <!-- ADD NEWS TREND BUTTON -->
        <b-button
          variant="outline-primary"
          class="ml-auto mr-1"
          @click="addNewsTrend()"
        >
          <feather-icon icon="PlusIcon" class="mr-50 text-primary" />
          <span>Tambah News Trend</span>
        </b-button>

        <!-- FORM SEARCH -->
        <div class="w-25">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                style="color: #7367f0 !important"
              />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Cari"
              v-model="table_data.filter"
              style="height: 39px"
            />
          </b-input-group>
        </div>
      </b-card-header>

      <!-- TABLE -->
      <newsTrendTable
        :data="table_data"
        :deleteData="deleteNewsTrend"
        :editData="editNewsTrend"
        :detailData="showNewsTrend"
        class="mb-2"
      />
    </b-card>

    <!-- ADD NEWS TREND MODAL -->
    <newsTrendFormModal :data="modal_data" />

    <!-- EDIT NEWS TREND MODAL -->
    <newsTrendFormModal :data="edit_modal_data" />
  </div>
</template>
  <script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import newsTrendTable from "@/components/newstrend/newsTrendTable.vue";
import newsTrendFormModal from "@/components/newstrend/newsTrendFormModal.vue";

export default {
  name: "NewsTrend",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    newsTrendTable,
    newsTrendFormModal,
  },

  data() {
    return {
      title: "News Trend | Online Media Monitoring",
      modal_data: {
        title: "Tambah News Trend",
        type: "add",
        id: "add-newstrend",
      },
      edit_modal_data: {
        title: "Edit News Trend",
        type: "edit",
        id: "edit-newstrend",
      },
      table_data: {
        filter: null,
        row: 10,
        fields: [
          {
            key: "title",
            label: "Judul",
            thClass: "text-left",
            tdClass: "text-left",
            sortable: true,
          },
          {
            key: "date",
            label: "Tanggal Buat",
            thClass: "text-center",
            tdClass: "text-center",
            sortable: true,
            thStyle: { width: "30%", paddingRight: "2rem" },
          },
          {
            key: "Action",
            label: "Action",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "15%" },
          },
        ],
        items: [
          {
            id: 1,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 2,
            title: "Pencegahan Penyakit",
            date: "09-09-2022",
          },
          {
            id: 3,
            title: "Pencegahan Sariawan",
            date: "09-09-2022",
          },
          {
            id: 4,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 5,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 6,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 7,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 8,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 9,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 10,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
          {
            id: 11,
            title: "Pencegahan Covid",
            date: "09-09-2022",
          },
        ],
      },
    };
  },

  created() {
    document.title = this.title;
  },

  methods: {
    // ADD NEWS TREND
    addNewsTrend() {
      this.$bvModal.show("add-newstrend");
    },
    // EDIT NEWS TREND
    editNewsTrend(id) {
      this.$bvModal.show("edit-newstrend");
    },
    // DETELE NEWS TREND
    deleteNewsTrend(id) {
      console.log(id);
    },
    // SHOW NEWS TREND
    showNewsTrend(id) {
      this.$router.push('/detail-news-trend')
    },
  },
};
</script>
  